exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".TransactionManagement-searchInput-109DH {\n    margin: 0 0 0 auto;\n}\n.TransactionManagement-titleSection-1sfOP {\n    display: grid;\n    grid-template-columns: repeat(2,1fr);\n}\n.TransactionManagement-badge-QDKcI {\n    position: relative;\n    top: -15px;\n    height: 25px; \n    display: inline-block;\n    min-width: 25px;\n    padding: 0 3px;\n    border-radius: 50%;\n    font-weight: 700;\n    font-size: 11px;\n    line-height: 24px;\n    color: #fff;\n    border: 1px solid #5EBE00;\n    background: #5EBE00;\n}\n@media screen and (max-width: 1200px) {\n    .TransactionManagement-titleSection-1sfOP {\n        grid-template-columns: repeat(1,1fr);\n    }\n    .TransactionManagement-searchInput-109DH {\n        margin: 0;\n    }\n}", "", {"version":3,"sources":["/home/wefud/WeEatsWeb/src/components/Shop/TransactionManagement/TransactionManagement.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;CACtB;AACD;IACI,cAAc;IACd,qCAAqC;CACxC;AACD;IACI,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,0BAA0B;IAC1B,oBAAoB;CACvB;AACD;IACI;QACI,qCAAqC;KACxC;IACD;QACI,UAAU;KACb;CACJ","file":"TransactionManagement.css","sourcesContent":[".searchInput {\n    margin: 0 0 0 auto;\n}\n.titleSection {\n    display: grid;\n    grid-template-columns: repeat(2,1fr);\n}\n.badge {\n    position: relative;\n    top: -15px;\n    height: 25px; \n    display: inline-block;\n    min-width: 25px;\n    padding: 0 3px;\n    border-radius: 50%;\n    font-weight: 700;\n    font-size: 11px;\n    line-height: 24px;\n    color: #fff;\n    border: 1px solid #5EBE00;\n    background: #5EBE00;\n}\n@media screen and (max-width: 1200px) {\n    .titleSection {\n        grid-template-columns: repeat(1,1fr);\n    }\n    .searchInput {\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"searchInput": "TransactionManagement-searchInput-109DH",
	"titleSection": "TransactionManagement-titleSection-1sfOP",
	"badge": "TransactionManagement-badge-QDKcI"
};