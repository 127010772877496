import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Container,
  FormControl,
  Accordion,
  Card,
  Button
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ModifierGroups.css';
import rs from '../../../../components/restaurantCommon.css';
import messages from '../../../../locale/messages';
import debounce from '../../../../helpers/debounce';
import Link from '../../../Link/Link';
// images
import EditIcon from '../../../../../public/RestaurantIcon/edit.png';
import TrashIcon from '../../../../../public/RestaurantIcon/dlty.png';
import downArrow from '../../../../../public/RestaurantIcon/Dropdownwithcircle.svg';
import upArrow from '../../../../../public/RestaurantIcon/Uparrowwithcircle.svg';
//components
import ConfirmationPopup from '../../../Common/ConfirmationPopup/ConfirmationPopup';
import CustomPagination from '../../../CustomPagination/CustomPagination';
import { connect } from 'react-redux';

//actions
import deleteModifier from '../../../../actions/shop/modifier/deleteModifier';
import updateModifierStatus from '../../../../actions/shop/modifier/updateModifierStatus';
import { openModal, closeModal } from '../../../../actions/siteadmin/modalActions';


class ModifierGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchList: '',
      collapseID: []
    }
    this.handleStatus = this.handleStatus.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.paginationData = this.paginationData.bind(this);
    this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse(id) {
    const { collapseID } = this.state;
    const index = collapseID.indexOf(id);
    if (index > -1) {
      collapseID.splice(index, 1);
      this.setState({ collapseID });
    } else {
      collapseID.push(id);
      this.setState({ collapseID });
    }
  }

  handleKeywordSearch(searchList) { // Keyword search
    const { modifierDetails: { refetch } } = this.props;
    this.setState({ searchList, currentPage: 1, collapseID: [] });
    refetch({ currentPage: 1, searchList });
  }

  async handleStatus(id, status, currentPage) {
    const { updateModifierStatus, modifierDetails: { refetch } } = this.props;
    const { searchList } = this.state;
    let variables = { currentPage, searchList };
    await updateModifierStatus(id, status, currentPage, searchList);
    refetch(variables);
  };

  async handleDelete(id, currentPage) {
    const { deleteModifier, modifierDetails: { refetch }, closeModal } = this.props;
    const { searchList } = this.state;
    let variables = { currentPage, searchList };
    await deleteModifier(id, currentPage, searchList);
    await closeModal('modifierDeleteModal')
    refetch(variables);
  };

  async paginationData(currentPage) {
    const { modifierDetails: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  };

  render() {
    const { openModal, closeModal, modifierDeleteModal, modalData, modifierDetails } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, collapseID } = this.state;
    let modifierDelete = 'modifierDelete';

    return (
      <div className={'mainContainer'}>
        <div>
          <ConfirmationPopup
            modalStatus={modifierDeleteModal}
            title={formatMessage(messages.deleteModifierTitle)}
            body={formatMessage(messages.deleteConfirmation)}
            closeModal={() => closeModal('modifierDeleteModal')}
            popupButtonLeftName={formatMessage(messages.cancelButton)}
            popupButtonRightName={formatMessage(messages.deleteAction)}
            popupButtonLeftFunction={() => closeModal('modifierDeleteModal')}
            popupButtonRightFunction={() => this.handleDelete(modalData.id, modalData.currentPage)}
          />
        </div>
        <Container fluid>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <h1 className={rs.restaurantTitleText}>{formatMessage(messages.modifierGroup)}</h1>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className={cx(rs.searchInputRestaurant, s.floatLeft, 'floatRightRTL')}>
                <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleKeywordSearch(e.target && e.target.value)} className={cx(rs.formControlInputRestaurant, rs.searchInputWidth)} />
              </div>
              <div className={cx(s.displayInlineBlock, rs.searchTopBtn, s.floatRight, 'floatLeftRTL', 'floatinhertRTL')}>
                <Link to={"/restaurant/menu"} className={cx(rs.button, rs.btnPrimaryBorder, s.displayInlineBlock, s.modifierGroupBtn, 'groupBtnRight')}>{formatMessage(messages.back)}</Link>
                <Link to={"/restaurant/menu/modifier-groups/add"} className={cx(rs.button, rs.btnPrimary, s.displayInlineBlock, s.btnMb)}>{formatMessage(messages.addModifierGroup)}</Link>
              </div>
            </Col>
          </Row>

          {
            modifierDetails && modifierDetails.getAllModifier && modifierDetails.getAllModifier.results && modifierDetails.getAllModifier.results.length > 0 && modifierDetails.getAllModifier.results.map((item, index) => {
              return (
                <div className='modifierAcccordion'>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <div className={s.menuItemGrid}>
                          <div className={cx(s.menuFirstSection, 'menuFirstSectionRTL')}>
                            <div className={cx(rs.alignLeft, rs.tabSection, s.paddingLeft, 'textAlignRightRTL')}>
                              <p className={cx(rs.idTitle, rs.textOverFlow)}>
                                {item.modifierName}
                              </p>
                            </div>
                            <div className={'restaurantActiveSelect'}>
                              <select value={item.isActive} onChange={(e) => { this.handleStatus(item.id, e.target.value, currentPage) }} className={cx(rs.activeSelect, rs.restaurantSelectInput)}>
                                <option value={"true"}>{formatMessage(messages.active)}</option>
                                <option value={"false"}>{formatMessage(messages.inactive)}</option>
                              </select>
                            </div>
                            <div>
                              <Link to={'/restaurant/menu/modifier-groups/edit/' + item.id} className={cx(rs.linkColor, s.displayInlineBlock)}>
                                <img src={EditIcon} className={cx(s.iconsWidth, 'iconsWidthRTL')} />
                                <span className={cx(s.vtrMiddle, s.editSection)}>{formatMessage(messages.editItem)}</span>
                              </Link>
                            </div>
                            <div>
                              <Button className={cx(rs.deleteBtn, s.editBtn, s.IconLeft, rs.textOverFlow)} onClick={() => openModal('modifierDeleteModal', { id: item.id, modalName: 'modifierDeleteModal', currentPage })}>
                                <img src={TrashIcon} className={cx(s.iconsWidth, 'trashIconRTL')} />
                                <span className={s.vtrMiddle}>{formatMessage(messages.deleteAction)}</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <Accordion.Toggle variant="link" eventKey="1" className={'arrowBtnDropDown'} onClick={() => this.toggleCollapse(item.id)}>
                          <img src={collapseID.includes(item.id) ? upArrow : downArrow} width={'30px'} height={'30px'}/>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          {
                            item.modifierItems && item.modifierItems.length > 0 && <span>{formatMessage(messages.item)}: </span>
                          }
                          {
                            item.modifierItems && item.modifierItems.length > 0 && item.modifierItems.map(function (elem) {
                              return elem.modifierItemName;
                            }).join(", ")
                          }</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              )
            })
          }
          {
            modifierDetails && modifierDetails.getAllModifier && modifierDetails.getAllModifier.results && modifierDetails.getAllModifier.results.length == 0 &&
            <span className={rs.notFoundText}><FormattedMessage {...messages.noModifier} />.</span>
          }
          {
            modifierDetails && modifierDetails.getAllModifier && modifierDetails.getAllModifier.results && modifierDetails.getAllModifier.results.length > 0
            && <div className={cx(s.space5, s.spaceTop5, 'restaurant')}>
              <CustomPagination
                total={modifierDetails.getAllModifier.count}
                currentPage={modifierDetails.getAllModifier.currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={formatMessage(messages.modifier)}
              />
            </div>
          }
        </Container>
      </div>
    );
  }
};
const mapDispatch = {
  deleteModifier,
  openModal,
  closeModal,
  updateModifierStatus
};
const mapState = (state) => ({
  modifierDeleteModal: state.modalStatus.modifierDeleteModal,
  modalData: state.modalStatus.data && state.modalStatus.data[0],
});

export default injectIntl((withStyles(s, rs))(connect(mapState, mapDispatch)(ModifierGroups)));