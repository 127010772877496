import gql from 'graphql-tag';
import history from '../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';


export function editRider(id, firstName, lastName, email, password, phoneDialCode, phoneNumber, userStatus, isBan, phoneCountryCode) {
  return async (dispatch, getState, { client }) => {

    const mutation = gql`
        
        mutation updateRider(
            $id: ID,
            $firstName: String,
            $lastName: String,
            $email: String,
            $password: String,
            $phoneDialCode: String
            $phoneNumber: String,
              $userStatus: String,
              $isBan: Int
              $phoneCountryCode: String
            ) {
            updateRider(
              id: $id
              firstName: $firstName
              lastName: $lastName
              email:$email
              password: $password
              phoneDialCode: $phoneDialCode
              phoneNumber: $phoneNumber
              userStatus: $userStatus
              isBan: $isBan
              phoneCountryCode: $phoneCountryCode
            )
            {
              status
              errorMessage
            }
          }
        `;

    dispatch(setLoaderStart('EditRider'));

    const { data } = await client.mutate({
      mutation,
      variables: {
        id,
        firstName,
        lastName,
        email,
        password,
        phoneDialCode,
        phoneNumber,
        userStatus,
        isBan,
        phoneCountryCode
      }
    });

    dispatch(setLoaderComplete('EditRider'));

    if (data && data.updateRider && data.updateRider.status === 200) {
      history.push('/siteadmin/users')
      toastr.success('Success', 'The eater information has been updated successfully!')
    } else {
      errorMessage = (data && data.updateRider && data.updateRider.errorMessage) ? data.updateRider.errorMessage : errorMessage;
      toastr.error("Error!", errorMessage);
    }
  }
}