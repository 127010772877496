exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".DeclineReason-root-nA_jh {\n  padding-left: 0;\n  padding-right: 0;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  background: #d4d7e5;\n}\n.DeclineReason-container-IQX7j {\n  margin: 0 auto;\n}\n.DeclineReason-errorMessage-5Qtpb{\n  color: red\n}\n.DeclineReason-paddingLeft-KFi_Q {\n\tpadding-left: 8px;\n  font-size: 18px;\n  vertical-align: middle;\n  color: #3a3a3a !important;\n  font-weight: 100 !important;\n} ", "", {"version":3,"sources":["/home/wefud/WeEatsWeb/src/components/Shop/OrderManagement/DeclineReason/DeclineReason.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,oBAAoB;CACrB;AACD;EACE,eAAe;CAChB;AACD;EACE,UAAU;CACX;AACD;CACC,kBAAkB;EACjB,gBAAgB;EAChB,uBAAuB;EACvB,0BAA0B;EAC1B,4BAA4B;CAC7B","file":"DeclineReason.css","sourcesContent":[".root {\n  padding-left: 0;\n  padding-right: 0;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  background: #d4d7e5;\n}\n.container {\n  margin: 0 auto;\n}\n.errorMessage{\n  color: red\n}\n.paddingLeft {\n\tpadding-left: 8px;\n  font-size: 18px;\n  vertical-align: middle;\n  color: #3a3a3a !important;\n  font-weight: 100 !important;\n} "],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "DeclineReason-root-nA_jh",
	"container": "DeclineReason-container-IQX7j",
	"errorMessage": "DeclineReason-errorMessage-5Qtpb",
	"paddingLeft": "DeclineReason-paddingLeft-KFi_Q"
};