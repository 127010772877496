import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Button, FormControl, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';

// style
import s from './RestaurantsList.css';

// Helpers
import messages from '../../../../locale/messages';
import debounce from '../../../../helpers/debounce';

// components
import CustomPagination from '../../../CustomPagination/CustomPagination';
import Link from '../../../Link/Link';

// Images
import EditIcon from '../../../../../public/Icons/edit.png';
import TrashIcon from '../../../../../public/Icons/bin.svg';
import ExportImage from '../../../../../public/Icons/export.png';

// Redux
import deleteShop from '../../../../actions/siteadmin/ManageShop/deleteShop';
import updateShopStatus from '../../../../actions/siteadmin/ManageShop/updateShopStatus';

class RestaurantsList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
    }

    this.paginationData = this.paginationData.bind(this);
    this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this));
    this.handleStatus = this.handleStatus.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleKeywordSearch(searchList) {
    const { restaurantDetails: { refetch } } = this.props;

    let variables = {
      currentPage: 1,
      searchList
    };

    this.setState({ searchList, currentPage: 1 });
    refetch(variables);
  }

  async handleDelete(id) {
    const { deleteShop, restaurantDetails: { refetch } } = this.props;
    const { searchList } = this.state;
    let variables = { currentPage: 1, searchList };
    const deleteShopResponse = await deleteShop(id);
    if (deleteShopResponse && deleteShopResponse.status === 200) {
      this.setState({ currentPage: 1 });
      refetch(variables);
    }
  };

  paginationData(currentPage) {
    const { restaurantDetails: { refetch } } = this.props;
    const { searchList } = this.state;
    let variables = { currentPage, searchList };
    this.setState({ currentPage });
    refetch(variables);
  };

  async handleStatus(updateField, id, status, currentPage) {
    const { updateShopStatus, restaurantDetails: { refetch } } = this.props;
    const { searchList } = this.state;
    let variables = { currentPage, searchList };
    const updateShopStatusResponse = await updateShopStatus(updateField, id, status, null, 3);
    if (updateShopStatusResponse && updateShopStatusResponse.status === 200) {
      refetch(variables);
    }
  };

  render() {
    const { restaurantDetails } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, searchList } = this.state;

    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.exportDisplay, s.tabelExport)}>
          <div>
            <div className={s.searchInput}>
              <FormControl type='text' placeholder={formatMessage(messages.search)}
                onChange={(event) => this.handleKeywordSearch(event.target && event.target.value)}
                className={s.formControlInput} />
            </div>
          </div>
          <div className={s.exportTextSection}>
            {
              restaurantDetails && restaurantDetails.getAllShops && restaurantDetails.getAllShops.userData && restaurantDetails.getAllShops.userData.length > 0 && <a
                href={`/export-admin-data?type=shops&keyword=${searchList}`}
                className={cx('pull-right', s.exportText, 'floatLeftRTL')}>
                <span className={cx(s.vtrMiddle, s.exportText, 'exportTextRTL')}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                <span className={s.vtrTextBottom}>
                  <img src={ExportImage} className={s.exportImg} />
                </span>
              </a>
            }
          </div>
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.shopName} /></th>
                <th scope="col"><FormattedMessage {...messages.ownerName} /></th>
                <th scope="col"><FormattedMessage {...messages.email} /></th>
                <th scope="col"><FormattedMessage {...messages.phoneNumber} /></th>
                <th scope="col"><FormattedMessage {...messages.address} /></th>
                <th scope="col"><FormattedMessage {...messages.createdAt} /></th>
                <th scope="col"><FormattedMessage {...messages.userStatus} /></th>
                <th scope="col"><FormattedMessage {...messages.banStatus} /></th>
                <th scope="col"><FormattedMessage {...messages.action} /></th>
                <th scope="col"><FormattedMessage {...messages.deleteAction} /></th>
                <th scope="col"><FormattedMessage {...messages.document} /></th>
              </tr>
            </thead>
            <tbody>
              {
                restaurantDetails && restaurantDetails.getAllShops && restaurantDetails.getAllShops.userData && restaurantDetails.getAllShops.userData.length > 0 && restaurantDetails.getAllShops.userData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item.profile.profileId}</td>
                      <td data-label={formatMessage(messages.shopName)}>{item.profile && item.profile.shopName}</td>
                      <td data-label={formatMessage(messages.ownerName)}>{item.profile.firstName + ' ' + item.profile.lastName}</td>
                      <td data-label={formatMessage(messages.email)}>{item.email}</td>
                      <td data-label={formatMessage(messages.phoneNumber)}>{item.phoneDialCode + item.phoneNumber}</td>
                      <td data-label={formatMessage(messages.address)}>{item.profile.city + ', ' + item.profile.state + ', ' + item.profile.country + '.'}</td>
                      <td data-label={formatMessage(messages.createdAt)}>{moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                      <td data-label={formatMessage(messages.userStatus)}>
                        <select value={item.userStatus} onChange={(e) => { this.handleStatus('userStatus', item.id, e.target.value, currentPage) }} className={s.selectInput}>
                          <option value={'pending'}>{formatMessage(messages.pending)}</option>
                          <option value={'active'}>{formatMessage(messages.approve)}</option>
                          <option value={'inactive'}>{formatMessage(messages.decline)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.banStatus)}>
                        <select value={item.isBan} onChange={(e) => { this.handleStatus('isBan', item.id, e.target.value, currentPage) }} className={s.selectInput}>
                          <option value={1}>{formatMessage(messages.ban)}</option>
                          <option value={0}>{formatMessage(messages.permit)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.action)}>
                        <Link to={'/siteadmin/restaurant/edit/' + item.profile.profileId} className={'editAlignIcon'}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                      </td>
                      <td className={s.trashPadding} data-label={formatMessage(messages.deleteAction)}>
                        <Button onClick={() => this.handleDelete(item.id, currentPage)} className={s.iconBtn}>
                          <img src={TrashIcon} className={cx(s.editIcon, 'trashIconRTL', 'editIconRTL')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.deleteAction} />
                          </span>
                        </Button>
                      </td>
                      <td data-label={formatMessage(messages.document)}>
                        <Link to={'/siteadmin/restaurant/document/' + item.profile.profileId} className={'editAlignIcon'}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.document} />
                          </span>
                        </Link>
                      </td>
                    </tr>
                  )
                })
              }
              {
                restaurantDetails && restaurantDetails.getAllShops && restaurantDetails.getAllShops.userData.length == 0 && (
                  <tr>
                    <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          restaurantDetails && restaurantDetails.getAllShops && restaurantDetails.getAllShops.userData && restaurantDetails.getAllShops.userData.length > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={restaurantDetails.getAllShops.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.restaurants)}
            />
          </div>
        }
      </div >
    );
  }
}

const mapDispatch = {
  deleteShop,
  updateShopStatus
};
const mapState = (state) => ({});

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(RestaurantsList)));