import gql from 'graphql-tag';
import {
  REMOVE_PAYOUT_START,
  REMOVE_PAYOUT_SUCCESS,
  REMOVE_PAYOUT_ERROR,
} from '../../../constants';

import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { getPayouts } from './getPayouts';
import { toastr } from 'react-redux-toastr';
import history from '../../../history';

export function removePayout(id) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: REMOVE_PAYOUT_START,
    });

    dispatch(setLoaderStart('payoutRemove'));

    try {

      let mutation = gql`
        mutation removePayout($id: Int! ){
          removePayout(id: $id) {
              status
          }
        }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          id
        }
      });

      if (data && data.removePayout) {
        await dispatch({
          type: REMOVE_PAYOUT_SUCCESS
        });

        toastr.success('Success', 'Payout account have been removed.');

        await dispatch(setLoaderComplete('payoutRemove'));
        await dispatch(getPayouts());
        history.push('/restaurant/payout');
      }

      await dispatch(setLoaderComplete('payoutRemove'));

    } catch (error) {

      toastr.error('Oops!', 'Something went wrong. Please try again.');

      dispatch({
        type: REMOVE_PAYOUT_ERROR,
        payload: {
          error
        }
      });

      dispatch(setLoaderComplete('payoutRemove'));
      return false;
    };

    return true;
  };
};
