import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';

// Style
import s from './BookingList.css';

// Helpers
import messages from '../../../../locale/messages';
import debounce from '../../../../helpers/debounce';
import { bookingStatus } from '../../../../helpers/bookingStatus';
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirst';

// Components
import CustomPagination from '../../../CustomPagination';
import Link from '../../../Link';
import CurrencyConverter from '../../../CurrencyConverter';

// Image
import ExportImage from '../../../../../public/Icons/export.png';

export class BookingList extends Component {

    static propTypes = {
        bookingDetails: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.state = {
            currentPage: 1,
            keyword: ''
        };

        this.paginationData = this.paginationData.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this));
        this.calculateAdminEarnings = this.calculateAdminEarnings.bind(this);
    }

    handleKeywordSearch(keyword) {
        const { bookingDetails: { refetch }, requestType } = this.props
        let variables = {
            currentPage: 1,
            keyword,
            requestType
        };

        this.setState({ keyword, currentPage: 1 });
        refetch(variables);
    }

    paginationData(currentPage) {
        const { bookingDetails: { refetch }, requestType } = this.props;
        const { keyword } = this.state;

        let variables = {
            currentPage,
            keyword,
            requestType
        };

        this.setState({ currentPage });
        refetch(variables);
    }

    calculateAdminEarnings(data) {
        let earnings = 0, adminEarnings = 0, adminOffered = 0;

        earnings = Number(data.userServiceFare) + Number(data.shopServiceFare) + Number(data.driverServiceFare);
        adminEarnings = earnings;
        if (data.isSpecialDelivery) {
            if (Number(earnings) > Number(data.specialDeliveryFare)) {
                adminEarnings = Number(earnings) - Number(data.specialDeliveryFare);
            } else {
                adminEarnings = 0;
                adminOffered = Number(data.specialDeliveryFare) - Number(earnings);
            }
        }

        return {
            adminEarnings,
            adminOffered
        }
    }

    render() {
        const { formatMessage, locale } = this.props.intl;
        const { bookingDetails, bookingDetails: { getAllOrdersAdmin }, requestType } = this.props;
        const { currentPage, keyword } = this.state;

        let exportType = 'bookings', pageType = 'bookings/view';
        if (requestType === 'completed') {
            exportType = 'completed-bookings';
            pageType = 'completed-bookings/view';
        } else if (requestType === 'failed') {
            exportType = 'failed-bookings';
            pageType = 'failed-bookings/view';
        }

        return (
            <div className={cx(s.widthInner, s.tableSection, 'widthInnerNoBorder')}>
                <div className={cx(s.exportDisplay, s.tabelExport)}>
                    <div>
                        <div className={s.searchInput}>
                            <FormControl
                                type='text' placeholder={formatMessage(messages.search)}
                                onChange={(event) => this.handleKeywordSearch(event.target && event.target.value)}
                                className={s.formControlInput}
                            />
                        </div>
                    </div>
                    <div className={s.exportTextSection}>
                        {
                            bookingDetails && getAllOrdersAdmin && getAllOrdersAdmin.count > 0 && <a
                                href={`/export-admin-data?type=${exportType}&keyword=${keyword}`}
                                className={cx('pull-right', s.exportText, 'floatLeftRTL')}>
                                <span className={cx(s.vtrMiddle, s.exportText, 'exportTextRTL')}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                                <span className={s.vtrTextBottom}>
                                    <img src={ExportImage} className={s.exportImg} />
                                </span>
                            </a>
                        }
                    </div>
                </div>
                <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
                    <Table className="table">
                        <thead>
                            <tr>
                                <th scope="col"><FormattedMessage {...messages.orderId} /></th>
                                <th scope="col"><FormattedMessage {...messages.userLabel} /></th>
                                <th scope="col"><FormattedMessage {...messages.shopLabel} /></th>
                                <th scope="col"><FormattedMessage {...messages.driverLabel} /></th>
                                <th scope="col"><FormattedMessage {...messages.orderStatus} /></th>
                                <th scope="col"><FormattedMessage {...messages.paymentType} /></th>
                                <th scope="col"><FormattedMessage {...messages.orderAmount} /></th>
                                {
                                    requestType === 'completed' && <th scope="col"><FormattedMessage {...messages.earnings} /></th>
                                }
                                {
                                    requestType === 'completed' && <th scope="col"><FormattedMessage {...messages.overdraft} /></th>
                                }
                                <th scope="col"><FormattedMessage {...messages.action} /></th>
                                <th scope="col"><FormattedMessage {...messages.transactionsLabel} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bookingDetails && getAllOrdersAdmin && getAllOrdersAdmin.count > 0 && getAllOrdersAdmin.results.map((item, key) => {
                                    let earnings;
                                    if (requestType === 'completed') {
                                        earnings = this.calculateAdminEarnings(item);
                                    }

                                    return (
                                        <tr key={key}>
                                            <td data-label={formatMessage(messages.orderId)}>
                                                <Link to={`/siteadmin/${pageType}/${item.id}`}>
                                                    {`#${item.orderId}`}
                                                </Link>
                                            </td>
                                            <td data-label={formatMessage(messages.userLabel)}>
                                                {
                                                    item.userDetails && item.userDetails.firstName && <div>
                                                        {capitalizeFirstLetter(item.userDetails.firstName)}<br />
                                                        {item.userDetails.phoneNumber}
                                                    </div>
                                                }
                                                {
                                                    (!item.userDetails || !item.userDetails.firstName) && <FormattedMessage {...messages.notFound} />
                                                }
                                            </td>
                                            <td data-label={formatMessage(messages.shopLabel)}>
                                                {
                                                    item.shopDetails && item.shopDetails.shopName && <div>
                                                        {capitalizeFirstLetter(item.shopDetails.shopName)}<br />
                                                        {item.shopDetails.phoneNumber}
                                                    </div>
                                                }
                                                {
                                                    (!item.shopDetails || !item.shopDetails.shopName) && <FormattedMessage {...messages.notFound} />
                                                }
                                            </td>
                                            <td data-label={formatMessage(messages.driverLabel)}>
                                                {
                                                    item.deliveryPartnerDetails && item.deliveryPartnerDetails.firstName && <div>
                                                        {capitalizeFirstLetter(item.deliveryPartnerDetails.firstName)}<br />
                                                        {item.deliveryPartnerDetails.phoneNumber}
                                                    </div>
                                                }
                                                {
                                                    (!item.deliveryPartnerDetails || !item.deliveryPartnerDetails.firstName) && <FormattedMessage {...messages.notFound} />
                                                }
                                            </td>
                                            <td data-label={formatMessage(messages.orderStatus)}>{bookingStatus(item.bookingStatus, locale)}</td>
                                            <td data-label={formatMessage(messages.paymentType)}>
                                                {
                                                    item.paymentType === 1 && <FormattedMessage {...messages.cashOnHand} />
                                                }
                                                {
                                                    item.paymentType === 2 && <FormattedMessage {...messages.card} />
                                                }
                                                {
                                                    item.paymentType === 3 && <FormattedMessage {...messages.wallet} />
                                                }
                                            </td>
                                            <td data-label={formatMessage(messages.orderAmount)}>
                                                <CurrencyConverter
                                                    from={item.currency}
                                                    amount={item.userPayableFare}
                                                />
                                            </td>
                                            {
                                                requestType === 'completed' && earnings && <td data-label={formatMessage(messages.earnings)}>
                                                    <CurrencyConverter
                                                        from={item.currency}
                                                        amount={earnings.adminEarnings}
                                                    />
                                                </td>
                                            }
                                            {
                                                requestType === 'completed' && earnings && <td data-label={formatMessage(messages.overdraft)}>
                                                    <CurrencyConverter
                                                        from={item.currency}
                                                        amount={earnings.adminOffered}
                                                    />
                                                </td>
                                            }
                                            <td data-label={formatMessage(messages.action)}>
                                                <Link to={`/siteadmin/${pageType}/${item.id}`}>
                                                    <FormattedMessage {...messages.view} />
                                                </Link>
                                            </td>
                                            <td data-label={formatMessage(messages.action)}>
                                                <Link to={`/siteadmin/transaction-history/${exportType}/${item.id}`}>
                                                    <FormattedMessage {...messages.transactionsLabel} />
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                getAllOrdersAdmin && getAllOrdersAdmin.count == 0 && <tr>
                                    <td colSpan={requestType === 'completed' ? 10 : 8} className={s.noRecords}>
                                        <FormattedMessage {...messages.noResult} />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </div>
                {
                    getAllOrdersAdmin && getAllOrdersAdmin.count > 0 && <div className={cx(s.space5, s.spaceTop5)}>
                        <CustomPagination
                            total={getAllOrdersAdmin.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.ordersLabel)}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default injectIntl(withStyles(s)(BookingList));