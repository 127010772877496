import { toastr } from 'react-redux-toastr';
import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../../constants';
import checkPaymentMethod from '../../../components/Shop/Payout/PayoutForm/getPaymentMethods.graphql';
import history from '../../../history';

export function checkPayout() {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: ADD_PAYOUT_START
      });

      const { data } = await client.query({
        query: checkPaymentMethod,
        fetchPolicy: 'network-only'
      });

      if (data && data.getPaymentMethods && data.getPaymentMethods.length > 0) {
        if (!data.getPaymentMethods[0].isEnable) {
          dispatch({
            type: ADD_PAYOUT_ERROR
          });
          toastr.warning('Error!', 'This feature is currently not available. Please contact admin for more information.');
          return;
        } else {
          dispatch({
            type: ADD_PAYOUT_SUCCESS
          });
          history.push('/restaurant/addpayout');
        }
      }

    } catch (error) {
      dispatch({
        type: ADD_PAYOUT_ERROR
      });
    }
  };
};